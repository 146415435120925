import '../styles/index.scss';
import './password-strength-calculator.js';
'use strict';

(function () {

    if (!Element.prototype.closest) {

        Element.prototype.closest = function (css) {
            var node = this;

            while (node) {
                if (node.matches(css)) return node;else node = node.parentElement;
            }
            return null;
        };
    }
})();

(function () {

    if (!Element.prototype.matches) {

        Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.webkitMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector;
    }
})();

document.addEventListener('DOMContentLoaded', function () {

  

    // // Get all "navbar-burger" elements
    // var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // // Check if there are any navbar burgers
    // if ($navbarBurgers.length > 0) {

    //     // Add a click event on each of them
    //     $navbarBurgers.forEach(function (el) {
    //         el.addEventListener('click', function () {
                
    //             // Get the target from the "data-target" attribute
    //             var target = el.dataset.target;
    //             var $target = document.getElementById(target);

    //             // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    //             el.classList.toggle('is-active');
    //             $target.classList.toggle('is-active');
               
    //         });
    //     });
    // }

    // // Handle click on close button in nav menu
    // var $closeBtns = Array.prototype.slice.call(document.querySelectorAll('.navbar-menu .close-btn'), 0);

    // if ($closeBtns.length > 0) {
    //     $closeBtns.forEach(function (el) {
    //         el.addEventListener('click', function (e) {
    //             e.preventDefault();
    //             el.closest('.navbar-menu').classList.remove('is-active');
                
    //         });
    //     });

        
    // }


    // // Handle click on overlay when nav menu is opened 
    // var $overlay = document.querySelector('.overlay');
    // $overlay.addEventListener('click', function () {
    //     document.querySelector('.navbar-menu.is-active').classList.remove('is-active');
    // });

    //team member modal
    var $teamMembersItems = Array.prototype.slice.call(document.querySelectorAll('.team .team-member-img'), 0);
    if ($teamMembersItems.length > 0) {
        $teamMembersItems.forEach(function (el) {
            el.addEventListener('click', function () {
                el.closest('.modal-item').querySelector('.modal').classList.add('is-active');
                el.closest('html').classList.add('modal-open');
            });
        });
    }

    //handle click on close button in modal windows
    var $closeModalButton = Array.prototype.slice.call(document.querySelectorAll('.modal-close'), 0);
    if ($closeModalButton) {
        $closeModalButton.forEach(function (el) {
            el.addEventListener('click', function () {
                el.closest('.modal.is-active').classList.remove('is-active');
                el.closest('html').classList.remove('modal-open');
            });
        });
    }

    //open submenu in nav meny by clicking on it
    var $navbarLink = Array.prototype.slice.call(document.querySelectorAll('#menu-item-dropdown-20'), 0);
    if ($navbarLink.length > 0) {
        $navbarLink.forEach(function (el) {
            el.addEventListener('click', function () {
                el.closest('.dropdown-menu-active').classList.toggle('.dropdown-menu-active');
            });
        });
    }

    function elementInViewport2(el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return top < window.pageYOffset + window.innerHeight && left < window.pageXOffset + window.innerWidth && top + height > window.pageYOffset && left + width > window.pageXOffset;
    }
    var scrollPos = 0;
    window.addEventListener('scroll', function () {

        var scrolled = window.scrollY;

        var circlesBefore = Array.prototype.slice.call(document.querySelectorAll(".circles-before"), 0);
        var circlesAfter = Array.prototype.slice.call(document.querySelectorAll(".circles-after"), 0);
        move_circles(circlesBefore, 1);
        move_circles(circlesAfter, 1);
        scrollPos = document.body.getBoundingClientRect().top;
    });

    function move_circles(circles, speed) {
        circles.forEach(function (elem) {
            if (elementInViewport2(elem)) {
                var y_translate = 0;
                if (document.body.getBoundingClientRect().top > scrollPos) {
                    if (getTranslate3d(elem).length > 0) {
                        y_translate = getTranslate3d(elem)[1];
                    }
                    y_translate += speed;
                } else {
                    if (getTranslate3d(elem).length > 0) {
                        y_translate = getTranslate3d(elem)[1];
                    }
                    y_translate -= speed;
                }

                elem.style.transform = "translate3d(0px," + y_translate + "px, 0px)";
            }
        });
    }

    function getTranslate3d(el) {
        var values = el.style.transform.split(/\w+\(|\);?/);
        if (!values[1] || !values[1].length) {
            return [];
        }
        var values_arr = values[1].split(/,\s?/g);
        return values_arr.map(function (el) {
            return parseInt(el.replace('px', ''));
        });
    }

    jQuery('.get_resource').on('click', function (e) {
        e.preventDefault();
        var resourceID = jQuery(this).data('resource-id');
        var password = prompt('Please enter password');
        console.log(password);
        if (password) {
            var data = {
                action: 'get_resource',
                resource_id: resourceID,
                password: password
            };
            jQuery.post(ajax.url, data, function (response) {
                response = JSON.parse(response);
                if (response.status == 'success') {
                    var win = window.open(response.url, '_blank');
                    win.focus();
                } else {
                    alert('something whent wrong. Please check password');
                }
            });
        }
    });

  

  
});

jQuery( document ).ready(function($) {

    $( ".menu-item-has-children.dropdown" ).mouseenter(function() {
        $(this).children(".dropdown-menu").css("display", "block");
    });
    $( ".menu-item-has-children.dropdown" ).mouseleave(function() {
        $(this).children(".dropdown-menu").css("display", "none");
    });

           

    $('.navbar-burger, .close-btn').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
    
    
        $('#navbarBasic').toggleClass('is-active');
        $('body').toggleClass('menu-open');
    
    
    $('.close-btn').one('click', function closeMenu (e){
        if($('#navbarBasic').has(e.target).length === 0){
            $('#navbarBasic').removeClass('is-active');
            $('body').removeClass('menu-open');
        } else {
            $('.close-btn').one('click', closeMenu);

            }
        });
        });

    $('#dismiss-alert').on('click', function(e) {
        e.preventDefault();
        setDismissedAlertCookie();
        $('.alert-bar').remove();
        $('header').css('transform', 'translateY(0)');
        
    });
    
    function setDismissedAlertCookie() {
        var date = new Date();
        date.setTime(date.getTime() + (48 * 60 * 60 * 1000)); // 48 hours
        var expires = "; expires=" + date.toUTCString();
        document.cookie = 'midnight-alert-dismissed' + "=" + 'true' + expires + "; path=/";
    };
    var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)midnight-alert-dismissed\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    var alertBar = document.querySelector('.alert-bar');
    if (cookieValue === 'true') {    
        if (alertBar) {
            alertBar.parentNode.removeChild(alertBar);
        }
    } else {
        if (alertBar) {
            alertBar.style.display = 'block';
        }
    }

    function adjustHeaderTransform() {
        if ($('.alert-bar').length > 0) {
            // Calculate the height of the .alert-bar element
            var alertBarHeight = $('.alert-bar').outerHeight();
            
            // Apply a transform translateY to the header element
            $('header').css('transform', 'translateY(' + alertBarHeight + 'px)');
        }
    }
    adjustHeaderTransform();
        // Adjust on window resize
        $(window).resize(function() {
            adjustHeaderTransform();
        });
    // services
    tabControl();

/*
We also apply the switch when a viewport change is detected on the fly
(e.g. when you resize the browser window or flip your device from 
portrait mode to landscape). We set a timer with a small delay to run 
it only once when the resizing ends. It's not perfect, but it's better
than have it running constantly during the action of resizing.
*/
var resizeTimer;
$(window).on('resize', function(e) {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {
    tabControl();
  }, 250);
});

/*
The function below is responsible for switching the tabs when clicked.
It switches both the tabs and the accordion buttons even if 
only the one or the other can be visible on a screen. We prefer
that in order to have a consistent selection in case the viewport
changes (e.g. when you resize the browser window or flip your 
device from portrait mode to landscape).
*/
function tabControl() {
  var tabs = $('.tabbed-content').find('.tabs');
  if(tabs.is(':visible')) {
    tabs.find('a').on('click', function(event) {
      event.preventDefault();
      var target = $(this).attr('href'),
          tabs = $(this).parents('.tabs'),
          
          buttons = tabs.find('a'),
          item = tabs.parents('.tabbed-content').find('.item');
      buttons.removeClass('active');
      item.removeClass('active');
      $(this).addClass('active');
      $(this).parent('li').addClass('active').siblings().removeClass('active'); // 
      $(target).addClass('active');
    });
  } else {
    $('.item').on('click', function() {
      var container = $(this).parents('.tabbed-content'),
          currId = $(this).attr('id'),
          items = container.find('.item');
      container.find('.tabs a').removeClass('active');
      items.removeClass('active');
      $(this).addClass('active');
      container.find('.tabs a[href$="#'+ currId +'"]').addClass('active');
    });
  } 
}

$('.dropdown-toggle').click(function() {
  var location = $(this).attr('href');
  window.location.href = location;
  return false;
  });

});
