
//For "Password Strength Calculator" page

document.addEventListener("DOMContentLoaded", function() {
  const times = {
      'Numbers Only': ["Instantly", "Instantly", "Instantly", "Instantly", "Instantly", "Instantly", "Instantly", "Instantly", "1 sec", "9 mins", "1 hour", "14 hours", "6 days"],
      'Lowercase Letters': ["Instantly", "Instantly", "Instantly", "Instantly", "Instantly", "Instantly", "3 secs", "58 mins", "14 hours", "27 years", "713 years", "18k years", "481k years"],
      'Upper and Lowercase Letters': ["Instantly", "Instantly", "Instantly", "1 sec", "28 secs", "21 mins", "6 years", "898k years", "46m years", "46m years", "2bn years", "126bn years"],
      'Numbers, Upper and Lowercase Letters': ["Instantly", "Instantly", "Instantly", "2 secs", "2 mins", "5 days", "53 years", "202k years", "46m years", "2bn years", "48bn years"],
      'Numbers, Upper and Lowercase Letters, Symbols': ["Instantly", "Instantly", "Instantly", "4 secs", "6 mins", "6 months", "226 years", "15k years", "7m years", "5bn years", "380bn years", "26tn years"]
  };

  const commonPasswords = [
      "123456", "password", "123456789", "12345678", "12345", "1234567", "1234567890", "qwerty", "abc123", "password1", 
      "1234", "111111", "123123", "admin", "letmein", "welcome", "monkey", "football", "123qwe", "123abc"
      // Add more common passwords as needed
  ];

  function getCharacterType(password) {
      const hasNumbers = /\d/.test(password);
      const hasLower = /[a-z]/.test(password);
      const hasUpper = /[A-Z]/.test(password);
      const hasSymbols = /[^a-zA-Z0-9]/.test(password);

      let type = '';
      if (hasNumbers) type += 'Numbers';
      if (hasLower) type += ' Lowercase';
      if (hasUpper) type += ' Uppercase';
      if (hasSymbols) type += ' Symbols';

      return type.trim();
  }

  function calculateTime() {
      const password = document.getElementById('password').value;
      const length = password.length;
      const characterType = getCharacterType(password);
      const warningElement = document.getElementById('warning');

      console.log('Password:', password);
      console.log('Character Type:', characterType);

      // Check for common password
      if (commonPasswords.includes(password)) {
          warningElement.textContent = 'This is a common password. Please try a different one.';
      } else {
          warningElement.textContent = '';
      }

      let time = "Instantly";
      let colorClass = "weak";
      let suggestions = "Use a mix of numbers, uppercase and lowercase letters, and symbols.";

      if (length < 4 || !characterType) {
          document.getElementById('output').innerText = "Too short or invalid character type.";
          document.getElementById('output').className = colorClass;
          document.getElementById('suggestions').innerText = suggestions;
          return;
      }

      let typeKey = '';
      if (characterType.includes('Numbers') && characterType.includes('Lowercase') && characterType.includes('Uppercase') && characterType.includes('Symbols')) {
          typeKey = 'Numbers, Upper and Lowercase Letters, Symbols';
      } else if (characterType.includes('Numbers') && characterType.includes('Lowercase') && characterType.includes('Uppercase')) {
          typeKey = 'Numbers, Upper and Lowercase Letters';
      } else if (characterType.includes('Lowercase') && characterType.includes('Uppercase')) {
          typeKey = 'Upper and Lowercase Letters';
      } else if (characterType.includes('Lowercase')) {
          typeKey = 'Lowercase Letters';
      } else if (characterType.includes('Numbers')) {
          typeKey = 'Numbers Only';
      }

      if (length <= 18) {
          time = times[typeKey][length - 4];
      } else {
          time = "Very long time!";
      }

      console.log('Time to brute force:', time);

      if (time.includes("Instantly") || time.includes("sec") || time.includes("min") || time.includes("hour") || time.includes("day")) {
          colorClass = "weak";
      } else if (time.includes("year") || time.includes("years")) {
          colorClass = "medium";
      } else {
          colorClass = "strong";
      }

      document.getElementById('output').innerText = time;
      document.getElementById('output').className = colorClass;
      document.getElementById('suggestions').innerText = suggestions;
  }

  function togglePassword() {
      const passwordField = document.getElementById('password');
      const showPasswordCheckbox = document.getElementById('showPassword');
      passwordField.type = showPasswordCheckbox.checked ? "text" : "password";
  }

  document.getElementById('password').addEventListener('input', calculateTime);
  document.getElementById('showPassword').addEventListener('change', togglePassword);
});
